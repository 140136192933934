import Vue from "vue";
import langPtBr from 'quasar/lang/pt-br';

import "@/styles/quasar.styl";
import "quasar/dist/quasar.ie.polyfills";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import {
  Quasar,
  QLayout,
  QRadio,
  QHeader,
  QDrawer,
  QPageContainer,
  QPage,
  QToolbar,
  QToolbarTitle,
  QBtn,
  QIcon,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QScrollArea,
  QInput,
  QSpace,
  QMenu,
  Ripple,
  QBtnDropdown,
  QTooltip,
  QAvatar,
  Meta,
  ClosePopup,
  QSeparator,
  QToggle,
  QCard,
  QCardSection,
  QCardActions,
  QForm,
  Notify,
  QDialog,
  QBar,
  QBadge,
  QExpansionItem,
  QTable,
  QTd,
  QTr,
  QTh,
  QCheckbox,
  Loading,
  QSpinnerRadio,
  QSelect,
  QPageSticky,
  Dialog,
  QEditor,
  QSpinner,
  QChip,
  QDate,
  QPopupProxy,
  QTimeline,
  QTimelineEntry,
  QTabPanels,
  QTabPanel,
  QTab,
  QTabs,
  QTree,
  QBtnGroup,
  QStepper,
  QStep,
  QStepperNavigation,
  QLinearProgress,
  QUploader,
  QSplitter,
  QFab,
  QFabAction,
  QMarkupTable,
  QField,
  QOptionGroup,
  QTime,
  QBtnToggle,
  QBanner,
  QColor,
  QCircularProgress,
  AppFullscreen,
  QPopupEdit,
} from "quasar";

Vue.use(Quasar, {
  config: {
    notify: {
      attrs: {
        style: 'margin-top: 75px;'
      }
    }
  },
  components: {
    QLayout,
    QRadio,
    QHeader,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QScrollArea,
    QInput,
    QSpace,
    QMenu,
    QBtnDropdown,
    QTooltip,
    QAvatar,
    QSeparator,
    QToggle,
    QCard,
    QCardSection,
    QCardActions,
    QForm,
    QDialog,
    QBar,
    QBadge,
    QExpansionItem,
    QTable,
    QTd,
    QTr,
    QTh,
    QCheckbox,
    QDialog,
    QSpinnerRadio,
    QSelect,
    QPageSticky,
    QEditor,
    QSpinner,
    QChip,
    QDate,
    QPopupProxy,
    QTimeline,
    QTimelineEntry,
    QTabPanels,
    QTabPanel,
    QTab,
    QTabs,
    QTree,
    QBtnGroup,
    QStepper,
    QStep,
    QStepperNavigation,
    QLinearProgress,
    QUploader,
    QSplitter,
    QFab,
    QFabAction,
    QMarkupTable,
    QField,
    QOptionGroup,
    QTime,
    QBtnToggle,
    QBanner,
    QColor,
    QCircularProgress,
    QPopupEdit,
  },
  directives: {
    Ripple,
    ClosePopup
  },
  lang: langPtBr,
  plugins: {
    Meta,
    Notify,
    Dialog,
    Loading,
    AppFullscreen,
  }
});
